import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Writing an access log";
export const _frontmatter = {};
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "writing-an-access-log",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#writing-an-access-log",
        "aria-label": "writing an access log permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Writing an access log`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#configuring-logging-framework"
          }}>{`Configuring logging framework`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#logback"
            }}>{`logback`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#log4j2"
            }}>{`log4j2`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#customizing-a-log-format"
          }}>{`Customizing a log format`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#retrieving-values-from-requestlog"
            }}>{`Retrieving values from RequestLog`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#customizing-timestamp-format"
            }}>{`Customizing timestamp format`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#customizing-an-access-log-writer"
          }}>{`Customizing an access log writer`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#customizing-an-access-logger"
          }}>{`Customizing an access logger`}</a></p>
      </li>
    </ul>
    <h2 {...{
      "id": "configuring-logging-framework",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#configuring-logging-framework",
        "aria-label": "configuring logging framework permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configuring logging framework`}</h2>
    <p>{`To write an access log, you need to configure a logging framework first. The following configurations are
simple examples of `}<inlineCode parentName="p">{`logback.xml`}</inlineCode>{` and `}<inlineCode parentName="p">{`log4j2.xml`}</inlineCode>{` respectively.`}</p>
    <h3 {...{
      "id": "logback",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#logback",
        "aria-label": "logback permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`logback`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<?xml version="1.0" encoding="UTF-8"?>
<configuration>
  <appender name="CONSOLE" class="ch.qos.logback.core.ConsoleAppender">
    <encoder>
      <pattern>%-4relative [%thread] %-5level %logger{35} - %msg%n</pattern>
    </encoder>
  </appender>

  <appender name="ACCESS" class="ch.qos.logback.core.rolling.RollingFileAppender">
    <file>access.log</file>
    <rollingPolicy class="ch.qos.logback.core.rolling.SizeAndTimeBasedRollingPolicy">
      <!-- daily rollover -->
      <fileNamePattern>access.%d{yyyy-MM-dd}-%i.log</fileNamePattern>
      <!-- each file should be at most 1GB, keep 30 days worth of history, but at most 30GB -->
      <maxFileSize>1GB</maxFileSize>
      <maxHistory>30</maxHistory>
      <totalSizeCap>30GB</totalSizeCap>
    </rollingPolicy>
    <encoder>
      <pattern>%msg%n</pattern>
    </encoder>
  </appender>

  <logger name="com.linecorp.armeria.logging.access" level="INFO" additivity="false">
    <appender-ref ref="ACCESS"/>
  </logger>

  <root level="DEBUG">
    <appender-ref ref="CONSOLE"/>
  </root>
</configuration>
`}</code></pre>
    <h3 {...{
      "id": "log4j2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#log4j2",
        "aria-label": "log4j2 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`log4j2`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<?xml version="1.0" encoding="UTF-8"?>
<Configuration status="WARN">
  <Appenders>
    <Console name="CONSOLE" target="SYSTEM_OUT">
      <PatternLayout pattern="%d{HH:mm:ss.SSS} [%t] %-5level %logger{36} - %msg%n"/>
    </Console>
    <RollingFile name="ACCESS" fileName="access.log"
                 filePattern="access.%d{MM-dd-yyyy}-%i.log.gz">
      <PatternLayout>
        <Pattern>%m%n</Pattern>
      </PatternLayout>

      <Policies>
        <!-- daily rollover -->
        <TimeBasedTriggeringPolicy/>
        <!-- each file should be at most 1GB -->
        <SizeBasedTriggeringPolicy size="1GB"/>
      </Policies>
      <!-- keep 30 archives -->
      <DefaultRolloverStrategy max="30"/>
    </RollingFile>
  </Appenders>

  <Loggers>
    <Logger name="com.linecorp.armeria.logging.access" level="INFO" additivity="false">
      <AppenderRef ref="ACCESS"/>
    </Logger>
    <Root level="DEBUG">
      <AppenderRef ref="CONSOLE"/>
    </Root>
  </Loggers>
</Configuration>
`}</code></pre>
    <h2 {...{
      "id": "customizing-a-log-format",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#customizing-a-log-format",
        "aria-label": "customizing a log format permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Customizing a log format`}</h2>
    <p>{`Access logging is disabled by default. If you want to enable it, you need to specify an `}<a parentName="p" {...{
        "href": "type://AccessLogWriter:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/logging/AccessLogWriter.html"
      }}>{`type://AccessLogWriter`}</a>{`.
You may use one of the pre-defined log formats.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.server.ServerBuilder;
import com.linecorp.armeria.server.logging.AccessLogWriter;

ServerBuilder sb = Server.builder();
// Use NCSA common log format.
sb.accessLogWriter(AccessLogWriter.common(), true);
// Use NCSA combined log format.
sb.accessLogWriter(AccessLogWriter.combined(), true);
// Use your own log format.
sb.accessLogFormat("...log format...");
...
`}</code></pre>
    <p>{`You can have different `}<a parentName="p" {...{
        "href": "typeplural://AccessLogWriter:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/logging/AccessLogWriter.html"
      }}>{`typeplural://AccessLogWriter`}</a>{` for `}<a parentName="p" {...{
        "href": "typeplural://VirtualHost:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/VirtualHost.html"
      }}>{`typeplural://VirtualHost`}</a>{` and `}<a parentName="p" {...{
        "href": "typeplural://Service:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Service.html"
      }}>{`typeplural://Service`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`ServerBuilder sb = Server.builder();
AccessLogWriter fallbackLogWriter = ...
sb.accessLogWriter(fallbackLogWriter);

sb.virtualHost("foo.com")
  .accessLogWriter(AccessLogWriter.combined(), true)
  .service((ctx, req) -> HttpResponse.of(OK)); // This service will log using the combined format.
  ...

sb.route()
  .get("/commonAccessLog")
  .accessLogWriter(AccessLogWriter.common(), true)
  .build((ctx, req) -> HttpResponse.of(OK)); // This service will log using the common format.

// This service will log using the fallbackLogWriter.
sb.service("/fallbackLogWriter", (ctx, req) -> HttpResponse.of(OK));
`}</code></pre>
    <p>{`Pre-defined log formats are as follows.`}</p>
    <p>{`Tokens for the log format are listed in the following table.`}</p>
    <table><thead parentName="table"><tr parentName="thead"><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><div style={{
              "width": "12rem"
            }}>Tokens</div></th><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Condition support`}</p></th><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Description`}</p></th></tr></thead><tbody parentName="table"><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`%A`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`No`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the local IP address`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`%a`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`No`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the IP address of the client who initiated a
request. Use `}<inlineCode parentName="p">{`%{c}a`}</inlineCode>{` format string to get the
remote IP address where the channel is connected
to, which may yield a different value when there
is an intermediary proxy server.
This value relies on the `}<inlineCode parentName="p">{`clientAddress()`}</inlineCode>{` and
`}<inlineCode parentName="p">{`remoteAddress()`}</inlineCode>{` methods of
`}<a parentName="p" {...{
                "href": "type://ServiceRequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html"
              }}>{`type://ServiceRequestContext`}</a>{`, please refer to
`}<a parentName="p" {...{
                "href": "/docs/server-basics#getting-an-ip-address-of-a-client-who-initiated-a-request"
              }}>{`Getting an IP address of a client who initiated a request`}</a>{`
about how to configure the
`}<a parentName="p" {...{
                "href": "type://ServerBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html"
              }}>{`type://ServerBuilder`}</a>{` to get the client address
you interest.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`%h`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`No`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the remote hostname or IP address if DNS
hostname lookup is not available`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`%I`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`No`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the request ID. Use `}<inlineCode parentName="p">{`%{short}I`}</inlineCode>{` format string to
get the short form.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`%l`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`No`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the remote logname of the user
(not supported yet, always write `}<inlineCode parentName="p">{`-`}</inlineCode>{`)`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`%u`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`No`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the name of the authenticated remote user
(not supported yet, always write `}<inlineCode parentName="p">{`-`}</inlineCode>{`)`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`%t`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`No`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the date, time and time zone that the request
was received, by default in `}<inlineCode parentName="p">{`strftime`}</inlineCode>{` format
%d/%b/%Y:%H:%M:%S %z.
(for example, `}<inlineCode parentName="p">{`10/Oct/2000:13:55:36 -0700`}</inlineCode>{`)
Refer to
`}<a parentName="p" {...{
                "href": "#customizing-timestamp-format"
              }}>{`Customizing timestamp format`}</a>{`
for more information.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`%r`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Yes`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the request line from the client
(for example, `}<inlineCode parentName="p">{`GET /path h2`}</inlineCode>{`)`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`%s`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`No`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the HTTP status code returned to the client`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`%b`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Yes`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the size of the object returned to the client,
measured in bytes`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`%{HEADER_NAME}i`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Yes`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the value of the specified HTTP request header
name`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`%{HEADER_NAME}o`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Yes`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the value of the specified HTTP response header
name`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`%{ATTRIBUTE_NAME}j`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Yes`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the value of the specified attribute name`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`%{REQUEST_LOG_NAME}L`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Yes`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the value of the specified variable of the
`}<a parentName="p" {...{
                "href": "type://RequestLog:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLog.html"
              }}>{`type://RequestLog`}</a>{`. Refer to
`}<a parentName="p" {...{
                "href": "#retrieving-values-from-requestlog"
              }}>{`Retrieving values from RequestLog`}</a>{`
for more information.`}</p></td></tr></tbody></table>
    <p>{`Some tokens can have a condition of the response status code and the log message can be omitted with
the condition.`}</p>
    <table><thead parentName="table"><tr parentName="thead"><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><div style={{
              "width": "25rem"
            }}>Example of a condition</div></th><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Description`}</p></th></tr></thead><tbody parentName="table"><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`%200b`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Write the size of the object returned to the
client only if the response code is `}<inlineCode parentName="p">{`200`}</inlineCode>{`.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`%200,304{User-Agent}i`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Write `}<inlineCode parentName="p">{`User-Agent`}</inlineCode>{` header value only if the
response code is `}<inlineCode parentName="p">{`200`}</inlineCode>{` or `}<inlineCode parentName="p">{`304`}</inlineCode>{`.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`%!200,304{com.example.armeria.Attribute#KEY}j`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Write the value of the specified attribute
only if the response code is neither `}<inlineCode parentName="p">{`200`}</inlineCode>{`
nor `}<inlineCode parentName="p">{`304`}</inlineCode>{`.`}</p></td></tr></tbody></table>
    <h3 {...{
      "id": "retrieving-values-from-requestlog",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#retrieving-values-from-requestlog",
        "aria-label": "retrieving values from requestlog permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Retrieving values from `}<inlineCode parentName="h3">{`RequestLog`}</inlineCode></h3>
    <p><a parentName="p" {...{
        "href": "type://RequestLog:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLog.html"
      }}>{`type://RequestLog`}</a>{` holds information about the request, so a user may want to write these values to his or
her access log file. To write them in a simple way, `}<inlineCode parentName="p">{`%{variable}L`}</inlineCode>{` token is provided with the following
supported variable:`}</p>
    <table><thead parentName="table"><tr parentName="thead"><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><div style={{
              "width": "13rem"
            }}>Name</div></th><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Description`}</p></th></tr></thead><tbody parentName="table"><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`method`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the HTTP method value of the request`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`path`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the absolute path part of the HTTP request URI`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`query`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the query part of the HTTP request URI`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`requestStartTimeMillis`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the time when the processing of the request started,
in milliseconds since the epoch`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`requestDurationMillis`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the duration that was taken to consume or produce the request
completely, in milliseconds`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`requestDurationNanos`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the duration that was taken to consume or produce the request
completely, in nanoseconds`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`requestLength`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the length of the request content`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`requestCause`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the cause of request processing failure. The class name of the
cause and the detail message of it will be contained if exists.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`requestContentPreview`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the preview of the request content`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`responseStartTimeMillis`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the time when the processing of the response started,
in milliseconds since the epoch`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`responseDurationMillis`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the duration that was taken to consume or produce the response
completely, in milliseconds`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`responseDurationNanos`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the duration that was taken to consume or produce the response
completely, in nanoseconds`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`responseLength`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the length of the response content`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`responseCause`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the cause of response processing failure. The class name of the
cause and the detail message of it will be contained if exists.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`responseContentPreview`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the preview of the response content`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`totalDurationMillis`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the amount of time taken since the request processing started and
until the response processing ended, in milliseconds`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`totalDurationNanos`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the amount of time taken since the request processing started and
until the response processing ended, in nanoseconds`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`sessionProtocol`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the session protocol of the request.
e.g. `}<inlineCode parentName="p">{`h1`}</inlineCode>{`, `}<inlineCode parentName="p">{`h2`}</inlineCode>{`, `}<inlineCode parentName="p">{`h1c`}</inlineCode>{` or `}<inlineCode parentName="p">{`h2c`}</inlineCode></p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`serializationFormat`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the serialization format of the request.
e.g. `}<inlineCode parentName="p">{`tbinary`}</inlineCode>{`, `}<inlineCode parentName="p">{`ttext`}</inlineCode>{`, `}<inlineCode parentName="p">{`tcompact`}</inlineCode>{`, `}<inlineCode parentName="p">{`tjson`}</inlineCode>{` or `}<inlineCode parentName="p">{`none`}</inlineCode></p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`scheme`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the scheme value printed as `}<inlineCode parentName="p">{`serializationFormat+sessionProtocol`}</inlineCode></p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`host`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the host name of the request`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`status`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the status code and its reason phrase of the response.
e.g. `}<inlineCode parentName="p">{`200 OK`}</inlineCode></p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`statusCode`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`the status code of the response. e.g. `}<inlineCode parentName="p">{`200`}</inlineCode></p></td></tr></tbody></table>
    <h3 {...{
      "id": "customizing-timestamp-format",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#customizing-timestamp-format",
        "aria-label": "customizing timestamp format permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Customizing timestamp format`}</h3>
    <p>{`You can specify a new date/time format for the `}<inlineCode parentName="p">{`%t`}</inlineCode>{` token with
`}<a parentName="p" {...{
        "href": "https://docs.oracle.com/javase/10/docs/api/java/time/format/DateTimeFormatter.html"
      }}>{`DateTimeFormatter`}</a>{`.
You can use one of the following formatters which is provided by JDK as a variable of the `}<inlineCode parentName="p">{`%t`}</inlineCode>{` token,
e.g. `}<inlineCode parentName="p">{`%{BASIC_ISO_DATE}t`}</inlineCode>{`. If you want to use your own pattern, you can specify it as the variable,
e.g. `}<inlineCode parentName="p">{`%{yyyy MM dd}t`}</inlineCode>{`.`}</p>
    <table><thead parentName="table"><tr parentName="thead"><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Formatter`}</p></th><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Description`}</p></th><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Example`}</p></th></tr></thead><tbody parentName="table"><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`BASIC_ISO_DATE`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Basic ISO date`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`20111203`}</inlineCode></p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`ISO_LOCAL_DATE`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`ISO Local Date`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`2011-12-03`}</inlineCode></p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`ISO_OFFSET_DATE`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`ISO Date with offset`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`2011-12-03+01:00`}</inlineCode></p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`ISO_DATE`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`ISO Date with or without offset`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`2011-12-03+01:00`}</inlineCode>{`; `}<inlineCode parentName="p">{`2011-12-03`}</inlineCode></p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`ISO_LOCAL_TIME`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Time without offset`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`10:15:30`}</inlineCode></p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`ISO_OFFSET_TIME`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Time with offset`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`10:15:30+01:00`}</inlineCode></p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`ISO_TIME`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Time with or without offset`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`10:15:30+01:00`}</inlineCode>{`; `}<inlineCode parentName="p">{`10:15:30`}</inlineCode></p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`ISO_LOCAL_DATE_TIME`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`ISO Local Date and Time`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`2011-12-03T10:15:30`}</inlineCode></p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`ISO_OFFSET_DATE_TIME`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Date Time with Offset`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`2011-12-03T10:15:30+01:00`}</inlineCode></p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`ISO_ZONED_DATE_TIME`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Zoned Date Time`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`2011-12-03T10:15:30+01:00[Europe/Paris]`}</inlineCode></p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`ISO_DATE_TIME`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Date and time with ZoneId`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`2011-12-03T10:15:30+01:00[Europe/Paris]`}</inlineCode></p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`ISO_ORDINAL_DATE`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Year and day of year`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`2012-337`}</inlineCode></p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`ISO_WEEK_DATE`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Year and Week`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`2012-W48-6`}</inlineCode></p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`ISO_INSTANT`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Date and Time of an Instant`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`2011-12-03T10:15:30Z`}</inlineCode></p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`RFC_1123_DATE_TIME`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`RFC 1123 / RFC 822`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`Tue, 3 Jun 2008 11:05:30 GMT`}</inlineCode></p></td></tr></tbody></table>
    <h2 {...{
      "id": "customizing-an-access-log-writer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#customizing-an-access-log-writer",
        "aria-label": "customizing an access log writer permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Customizing an access log writer`}</h2>
    <p>{`You can specify your own log writer which implements a `}<inlineCode parentName="p">{`Consumer`}</inlineCode>{` of `}<a parentName="p" {...{
        "href": "type://RequestLog:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLog.html"
      }}>{`type://RequestLog`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`ServerBuilder sb = Server.builder();
sb.accessLogWriter(requestLog -> {
    // Write your access log with the given RequestLog instance.
    ...
}, true);
`}</code></pre>
    <h2 {...{
      "id": "customizing-an-access-logger",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#customizing-an-access-logger",
        "aria-label": "customizing an access logger permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Customizing an access logger`}</h2>
    <p>{`Armeria uses an SLF4J logger whose name is based on a reversed domain name of each virtual host by
default, e.g.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`com.linecorp.armeria.logging.access.com.example`}</inlineCode>{` for `}<inlineCode parentName="li">{`*.example.com`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`com.linecorp.armeria.logging.access.com.linecorp`}</inlineCode>{` for `}<inlineCode parentName="li">{`*.linecorp.com`}</inlineCode></li>
    </ul>
    <p>{`Alternatively, you can specify your own mapper or logger for a `}<a parentName="p" {...{
        "href": "type://VirtualHost:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/VirtualHost.html"
      }}>{`type://VirtualHost`}</a>{`, e.g.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`ServerBuilder sb = Server.builder();

// Using the specific logger name.
sb.accessLogger("com.example.my.access.logs");
...

// Using your own logger.
Logger logger = LoggerFactory.getLogger("com.example2.my.access.logs");
sb.accessLogger(Logger);
...

// Using the mapper which sets an access logger with the given VirtualHost instance.
sb.accessLogger(virtualHost -> {
    // Return the logger.
    // Do not return null. Otherwise, it will raise an IllegalStateException.
    return LoggerFactory.getLogger("com.example.my.access.logs." + virtualHost.defaultHostname());
});
...
`}</code></pre>
    <p>{`You can also specify your own logger for the specific `}<a parentName="p" {...{
        "href": "type://VirtualHost:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/VirtualHost.html"
      }}>{`type://VirtualHost`}</a>{`. In this case, the mapper or logger
you set for a specific `}<a parentName="p" {...{
        "href": "type://VirtualHost:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/VirtualHost.html"
      }}>{`type://VirtualHost`}</a>{` will override the access logger set via
`}<a parentName="p" {...{
        "href": "type://ServerBuilder#accessLogger(Logger):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html#accessLogger(org.slf4j.Logger)"
      }}>{`type://ServerBuilder#accessLogger(Logger)`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// Using the specific logger name.
sb.virtualHost("*.example.com")
  .accessLogger("com.example.my.access.logs")
  .and()
...

// Using your own logger.
Logger logger = LoggerFactory.getLogger("com.example2.my.access.logs");
sb.virtualHost("*.example2.com")
  .accessLogger(Logger)
  .and()
...

// Using the mapper which sets an access logger with the given VirtualHost instance.
sb.virtualHost("*.example3.com")
  .accessLogger(virtualHost -> {
    // Return the logger.
    // Do not return null. Otherwise, it will raise an IllegalStateException.
    return LoggerFactory.getLogger("com.example.my.access.logs." + virtualHost.defaultHostname());
  })
...
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      